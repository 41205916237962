import { SentryService } from '@propify-auth-client/services';
import { Suspense, useMemo } from 'react';
import type { RouteObject } from 'react-router';
import { Navigate, Outlet } from 'react-router';
import ErrorBoundary from '../pages/ErrorBoundary';
import PageLoading from '../pages/PageLoading';
import type { PropifyRoute } from '../routes';
import { routes } from '../routes';

const generateRoutes: (routesList: PropifyRoute[]) => RouteObject[] = (routesList) =>
  routesList.map((route) => ({
    path: route.path,
    element: route.redirect ? (
      <Navigate to={route.redirect} />
    ) : route.component ? (
      <Suspense fallback={<PageLoading />}>
        <route.component route={route} {...route.props} />
      </Suspense>
    ) : (
      <Outlet />
    ),
    children: !route.redirect && route.routes ? generateRoutes(route.routes) : undefined,
    ErrorBoundary,
  }));

export const useRouter = () => {
  const router = useMemo(
    () =>
      SentryService.sentryCreateBrowserRouter([
        {
          path: '/',
          element: <Outlet />,
          children: generateRoutes(routes),
          ErrorBoundary,
        },
      ]),
    [],
  );

  return { router };
};
