import { BrowserTracing as n, reactRouterV6Instrumentation as s, init as i, captureException as c, wrapCreateBrowserRouter as a } from "@sentry/react";
import { useEffect as p } from "react";
import { useLocation as u, useNavigationType as m, createRoutesFromChildren as E, matchRoutes as d } from "react-router";
import { createBrowserRouter as f } from "react-router-dom";
const w = {
  dsn: "https://6b916b2490634b36a5434c31d23c5797@o372675.ingest.sentry.io/5896648",
  integrations: [
    new n({
      routingInstrumentation: s(
        p,
        u,
        m,
        E,
        d
      )
    })
  ],
  tracesSampleRate: 1
}, h = {
  init: (t = {}) => {
    const r = process.env.NODE_ENV === "production", o = window.location.href.includes("stage") ? "stage" : "production", e = process.env.RELEASE_NAME;
    r && e && i({
      ...w,
      ...t,
      environment: o,
      release: e
    });
  },
  trackError: c,
  sentryCreateBrowserRouter: a(f)
};
export {
  h as SentryService
};
