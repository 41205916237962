import { createTheme } from '../components/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0f6eff',
      dark: '#333333',
    },
    secondary: {
      main: '#49B0E4',
    },
  },
});

export default theme;
