import e from "axios";
const i = e.create({
  baseURL: "/rest",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  },
  withCredentials: !0,
  paramsSerializer: {
    indexes: null
  }
});
export {
  i as apiClient
};
