import { SentryService } from '@propify-auth-client/services';
import type { FC } from 'react';
import { useRouteError } from 'react-router';
import NotFoundPage from '../pages/404';
import ErrorPage from '../pages/error';

const ErrorBoundary: FC = () => {
  const error: any = useRouteError();
  if (error && error.status === 404 && error.internal) {
    return <NotFoundPage />;
  }

  SentryService.trackError(error);
  return <ErrorPage />;
};

export default ErrorBoundary;
