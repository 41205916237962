import { lazy } from 'react';

const ChangePasswordPage = lazy(() => import('./pages/ChangePassword'));
const LoginPage = lazy(() => import('./pages/Login'));
const ResetPasswordPage = lazy(() => import('./pages/ResetPassword'));
const SignUpPage = lazy(() => import('./pages/SignUp'));

export type PropifyRoute = {
  path: string;
  component?: Function;
  routes?: PropifyRoute[];
  redirect?: string;
  props?: Record<string, unknown>;
};

export const routes: PropifyRoute[] = [
  {
    path: '/login',
    component: LoginPage,
  },
  {
    path: '/sign-up',
    component: SignUpPage,
  },
  {
    path: '/change-password',
    component: ChangePasswordPage,
  },
  {
    path: '/password/reset',
    component: ResetPasswordPage,
  },
];
